<template>
  <div class="home-info">
    <div class="home-photo"></div>
    <h1 class="home-title">Hello, I'm Stanislav Morozik</h1>
    <div class="home-text">
      {{
        `
      5 years of website development experience.\n
      3 years of experience developing SPA applications.\n\n
      I develop websites, online stores, CRM systems, landing pages on VueJS, PHP (e7, bitrix, yii, laravel).\n\n
      I create thoughtful interactive dynamic SPA applications on VueJS, React / Redux. I’m developing component libraries for web interfaces on React / Styled-Components stack. I develop the front and backend architecture of the project from scratch, design and develop the API.\n\n
      I am writing web services on Node.js with deployment in Docker containers. I work with MongoDB, MySQL, Redis.\n\n
      I can solve a variety of different JS-tasks: from interactive web banners development to working with graphics in d3.js.\n\n
      I am writing high-quality, optimized and easy to read code. I am willingly introducing modern technologies, writing in JS ES2020.\n\n
      Attentive to details, accurate, sociable and friendly.
      `
      }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "Info",
}
</script>

<style lang="scss">
.home {
  &-info {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: min-content 1fr 124px;
    gap: 30px;
  }

  &-photo {
    width: 100px;
    height: 100px;

    border-radius: 50%;

    background: #202022 url("~assets/images/photo.webp") no-repeat center / cover;
  }

  &-title {
    font-weight: 900;
    font-size: 46px;
    line-height: 50px;
  }

  &-text {
    grid-column: 1 / 3;

    font-size: 16px;
    line-height: 24px;

    white-space: pre-line;
  }
}
</style>
