<template>
  <div class="home-contacts">
    <a class="home-contacts-item" href="https://github.com/moroz1k" target="_blank">
      <img :src="github" alt="github">
      Github
    </a>
    <a class="home-contacts-item" href="https://t.me/m0roz1k" target="_blank">
      <img :src="telegram" alt="telegram">
      Telegram
    </a>
    <a class="home-contacts-item" href="mailto:stanislav.morozik@gmail.com" target="_blank" v-if="false">
      <img :src="email" alt="email">
      Mail
    </a>
  </div>
</template>

<script>
import github from 'assets/svg/github.svg'
import telegram from 'assets/svg/telegram.svg'
import email from 'assets/svg/email.svg'

export default {
  name: "Contacts",

  data: () => ({
    github,
    telegram,
    email
  })
}
</script>

<style lang="scss">
.home {
  &-contacts {
    display: grid;
    grid-template-columns: min-content min-content min-content;
    gap: 30px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      line-height: 18px;

      cursor: pointer;

      svg, img {
        margin-right: 8px;
      }

      @include hover();
    }
  }
}
</style>
