<template>
  <div class="home">
    <div class="container home-container">

      <Info>
        <Contacts />
      </Info>

      <List />
    </div>
  </div>
</template>

<script>
import List from './Home/List'
import Info from './Home/Info'
import Contacts from './Home/Contacts'

export default {
  components: { Contacts, Info, List },
}
</script>
<style type="text/scss" lang="scss">
.home {
  height: 100%;
  padding: 100px 0 0;

  &-container {
    height: 100%;

    display: grid;
    gap: 30px;
    grid-template-columns: 6fr 8fr;
  }
}
</style>
