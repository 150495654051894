import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    meta: {
      isSecure: true,
    },
  },
  {
    path: '/admin/users',
    name: 'admin.users',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminUsers.vue'),
    meta: {
      isSecure: true,
    },
  },
  {
    path: '/admin/portfolio',
    name: 'admin.portfolio',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminPortfolio.vue'),
    meta: {
      isSecure: true,
    },
  },
  {
    path: '/admin/login',
    name: 'admin.login',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminLogin.vue'),
  },
  {
    path: '/:id',
    name: 'Item',
    component: () => import(/* webpackChunkName: "about" */ '../views/Item.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const isLogged = store.state.users.isLogged

  if (to.meta && to.meta.isSecure && !isLogged) {
    router.push('/admin/login')
  }

  next()
})

export default router
