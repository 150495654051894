<template>
  <div class="home-list">
    <router-link
        class="home-item"
        v-for="(item) in list"
        :to="item.id"
        :key="item.id"
    >
      <div class="home-item-inner">
        <div class="home-item-inner-photo" :style="{backgroundImage: `url(${item.preview})`}"></div>
      </div>
      <div class="home-item-content">
        <div class="home-item-title">{{ item.title }}</div>
        <div>
          {{ item.textPreview }}
        </div>
        <div class="home-item-link">
          Перейти <img :src="externalLink" alt="link" />
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import externalLink from 'assets/svg/external-link.svg'
import { mapState } from 'vuex'

export default {
  name: 'List',

  data: () => ({
    externalLink,
  }),

  computed: {
    ...mapState({
      list: s => s.list.list
    })
  }
}
</script>

<style lang="scss">
.home {
  $padding: 30px;

  &-list {
    padding: 30px;
    max-height: calc(100% - 0px);

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 264px + $padding;
    grid-auto-rows: 264px + $padding;
    gap: 0 30px;

    overflow-y: auto;
    overflow-x: hidden;

    @include scrollbar();
  }

  &-item {
    padding-bottom: $padding;

    display: grid;
    justify-content: stretch;
    align-items: stretch;

    position: relative;

    &:nth-child(2n) {
      margin-top: calc(#{$padding} * 2);
      margin-bottom: calc(-#{$padding} * 2);
    }

    &:hover &-inner {
      transform: scale(1.1);
    }

    &:hover &-inner > &-inner-photo {
      filter: opacity(.2) grayscale(50%);
    }

    &-inner {
      width: 100%;
      height: calc(100% - #{$padding});

      position: absolute;
      top: 0;
      left: 0;

      background: #202022;

      transition: transform 0.3s cubic-bezier(0.54, 1.5, 0.38, 1.11);

      cursor: pointer;

      &-photo {
        width: 100%;
        height: 100%;

        background: transparent no-repeat center / cover;

        transition: 0.3s cubic-bezier(0.54, 1.5, 0.38, 1.11);
        filter: opacity(0) grayscale(100%);
        cursor: pointer;
      }
    }

    &-content {
      width: 100%;
      height: 100%;
      padding: 32px;

      position: absolute;
      top: 0;
      left: 0;

      display: grid;
      grid-template-rows: auto 1fr 48px;
    }

    &-title {
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
    }

    &-link {
      display: flex;
      align-items: flex-start;

      font-size: 12px;
      line-height: 18px;

      opacity: 0.5;

      img {
        margin-left: 4px;
      }
    }
  }
}
</style>
