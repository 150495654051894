import fetchInstance, { getToken, setToken } from '@/api'

const isLogged = !!getToken

export default {
  namespaced: true,

  state: {
    isLogged,
    list: [],
  },

  mutations: {
    setList(state, list) {
      state.list = list
    },
    setLogged(state, bool) {
      state.isLogged = bool
    },
  },

  actions: {
    fetch({ commit }) {
      return fetchInstance('/api/users')
        .then(list => commit('setList', list))
    },
    create({ commit }) {
      return fetchInstance('/api/auth/register', {
        method: 'POST',
        body: JSON.stringify({
          login: 'test3',
          password: 'test3',
        }),
      })
        .then(console.log)
    },
    login({ commit }, { form }) {
      return fetchInstance(
        '/api/auth/login',
        {
          method: 'POST',
          body: JSON.stringify(form),
        },
      )
        .then((response) => {
          if (response.status === 'success') {
            const token = response.data.payload.token
            setToken(token)
            commit('setLogged', true)
          }
        })
    },
  },
}
