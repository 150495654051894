import router from '@/router'
import store from '@/store'

export const getToken = () => localStorage.getItem('bearer')
export const setToken = (token) => localStorage.setItem('bearer', token)
export const delToken = () => localStorage.removeItem('bearer')

const fetchInstance = (url = '', options = {}) => {
  const bearer = getToken()

  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  }

  if (bearer) defaultOptions.headers = {
    ...defaultOptions.headers,
    'Authorization': 'Bearer ' + bearer,
  }

  return fetch(
    url,
    {
      ...defaultOptions,
      ...options,
    },
  )
    .then(async (response) => {
      console.log('response', response)
      if (!response.ok) {
        if (response.status === 401) {
          store.commit('users/setLogged', false)
          delToken()
          await router.push('/admin/login')
        }
        throw Error(response.statusText)
      }

      store.commit('users/setLogged', true)

      return response.json()
    })
    .catch((response) => {

    })
}

export default fetchInstance
