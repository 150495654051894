import posm24Preview from 'assets/images/portfolio/posm24/preview.webp'

import agrealtyPreview from 'assets/images/portfolio/agrealty/preview.webp'
import shtabkvartirPreview from 'assets/images/portfolio/shtabkvartir/preview.webp'
import interstepPreview from 'assets/images/portfolio/interstep/preview.webp'

export default {
  namespaced: true,

  state: {
    list: [
      {
        id: 'agrealty',
        title: 'Agrealty',
        link: 'https://agrealty.ru/',
        textPreview: '',
        textFull: '',
        preview: agrealtyPreview,
        pics: [],
        tags: [
          'bitrix',
          'php',
          'scss',
          'vue',
          'vuex',
        ],
        stack: 'Bitrix, Vue',
      },
      {
        id: 'shtabkvartir',
        title: 'Shtabkvartir',
        link: 'https://shtabkvartir.ru/',
        textPreview: '',
        textFull: '',
        preview: shtabkvartirPreview,
        pics: [],
        tags: [
          'bitrix',
          'php',
          'scss',
          'vue',
          'vuex',
        ],
        stack: 'Bitrix, Vue',
      },
      {
        id: 'inter-step',
        title: 'Interstep',
        link: 'https://inter-step.ru/',
        textPreview: '',
        textFull: '',
        preview: interstepPreview,
        pics: [],
        tags: [
          'bitrix',
          'php',
          'scss',
          'vue',
          'vuex',
          'ssr'
        ],
        stack: 'Bitrix, Vue',
      },
    ],
  },

  getters: {
    getItem: state => link => state.list.find(item => item.id === link),
  },
}
